@import "./variables.css";
@import "./elements/index.css";

* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html,
body {
  min-height: 100%;
  background: var(--background-color);
  color: var(--background-contrast-color);
  margin: 0;
}
